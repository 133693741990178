import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import "./assets/style/common.less";
import "./assets/style/anime.css";
import i18n from "./lang";
import Toaster from "@meforma/vue-toaster";
import "vue-neat-modal/dist/vue-neat-modal.css";
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import moment from "moment";
moment.locale("ja");

const app = createApp(App);
app.use(Toaster).use(ElementPlus).use(i18n).provide("toast", app.config.globalProperties.$toast)
app.use(store).use(router).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

console.log = (function (log) {
    return process.env.VUE_APP_NO_CONSOLE == "1" ? function () { } : log;
})(console.log);

window["$toast"] = app.config.globalProperties.$toast;
app.config.globalProperties.$i18n = i18n;