import { createStore } from "vuex";
import { authApi } from "@/api";

let nowTimeTimer = null

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    nicnName: "",
    liverInfo: null,
    loadingShow: false,
    eventDetail: null,
    jointPhoto: null,
    trtcData: {
      client: null,
      remoteStream: null,
      localStream: null,
      streamingInfo: null,
    },
    eventExpress: "",
    eventId: "",
    serverDelay: 0,
    checkDelayOk: false,
    checkDelayPromise: null,
    nowTime: new Date().getTime(),
    vol: 0,
    networkIsLow: false,
    isBan: false,
    winHeight: 0,
    winWidth: 0,
    trySEFlag: false,
    countStartFlag: false
  },
  getters: {
    token: (state) => state.token,
    liverInfo: (state) => state.liverInfo,
    loadingShow: (state) => state.loadingShow,
    eventDetail: (state) => state.eventDetail,
    jointPhoto: (state) => state.jointPhoto,
    trtcData: (state) => state.trtcData,
    eventExpress: (state) => state.eventExpress,
    eventId: (state) => state.eventId,
    serverDelay: (state) => state.serverDelay,
    checkDelayOk: (state) => state.checkDelayOk,
    checkDelayPromise: (state) => state.checkDelayPromise,
    nowTime: (state) => state.nowTime,
    vol: (state) => state.vol,
    networkIsLow: (state) => state.networkIsLow,
    isBan: (state) => state.isBan,
    winHeight: (state) => state.winHeight,
    winWidth: (state) => state.winWidth,
    countStartFlag: (state) => state.countStartFlag,
  },
  mutations: {
    setIsBan (state, data) {
      state.isBan = data;
    },
    setNetworkIsLow (state, data) {
      state.networkIsLow = data;
    },
    setVol (state, data) {
      state.vol = data;
    },
    setNowTime (state) {
      clearInterval(nowTimeTimer)
      nowTimeTimer = setInterval(() => {
        state.nowTime = new Date().getTime() - state.serverDelay;
      }, 1000)
    },
    setToken (state, data) {
      state.token = data;
    },
    setLoadingShow (state, data) {
      state.loadingShow = data;
    },
    setEventDetail (state, data) {
      state.eventDetail = data;
    },
    setJointPhoto (state, data) {
      state.jointPhoto = data;
    },
    setTrtcData (state, data) {
      state.trtcData[data.key] = data.value;
    },
    setNickName (state, data) {
      state.nicnName = data;
    },
    setEventExpress (state, data) {
      state.eventExpress = data;
    },
    setEventId (state, data) {
      state.eventId = data;
    },
    setLoading (state, data) {
      state.loadingShow = data;
    },
    sendLog (state, data) {
      authApi.logSave({ logContent: data });
    },
    setWinHeight (state, data) {
      state.winHeight = data;
    },
    setWinWidth (state, data) {
      state.winWidth = data;
    },
    setCountStartFlag (state, data) {
      state.countStartFlag = data;
    },
    async checkDelay (state) {
      const average = (arr) => {
        return arr.reduce((acc, val) => acc + val, 0) / arr.length;
      };
      state.checkDelayPromise = new Promise(async (resolve, reject) => {
        const delayList = [];
        const maxCount = 10;
        let reqCount = 0;
        let isTimeOut = false
        setTimeout(() => {
          isTimeOut = true
        }, 2000)
        while (delayList.length < 5 && reqCount < maxCount && !isTimeOut) {
          const startTime = new Date().getTime();
          await authApi
            .syncTime()
            .then((res) => {
              const endTime = new Date().getTime();
              const serverTime = new Date(res.data.now).getTime();
              const delayTime =
                endTime - (serverTime - (endTime - startTime) / 2);
              console.log(delayTime);
              console.log(endTime - startTime, serverTime, endTime);
              if (endTime - startTime < 500) {
                delayList.push(delayTime);
                // state.serverDelay = Math.round(delayTime)
              }
            })
            .catch((err) => {
              console.error(err);
            });
          reqCount++;
        }
        if (delayList.length < 5) {
          console.log("checkDelay faild")
          // setTimeout(() => {
          //   this.dispatch("checkDelay");
          // }, 60000);
          state.checkDelayOk = false;
        } else {
          console.log("checkDelay ok")
          state.serverDelay = Math.round(average(delayList));
          state.checkDelayOk = true;
        }
        console.log("checkDelay end")
        resolve()
      });
    },
    trySE (state) {
      if (!state.trySEFlag) {
        const demoLiverVideo = document.getElementById("demoLiverVideo")
        if (demoLiverVideo) {
          demoLiverVideo.play()
          demoLiverVideo.pause()
        }
      }
      if (!state.trySEFlag && state.eventDetail.eventType === 2) {
        state.trySEFlag = true;
        const se1 = document.getElementById("shootSE1");
        const se2 = document.getElementById("shootSE2");
        se1.play();
        se2.play();
      }
    },
    setLiverInfo (state, data) {
      state.liverInfo = data;
    },
  },
  actions: {
    setIsBan ({ commit }, data) {
      commit("setIsBan", data);
    },
    setNetworkIsLow ({ commit }, data) {
      commit("setNetworkIsLow", data);
    },
    setVol ({ commit }, data) {
      commit("setVol", data);
    },
    setNowTime ({ commit }) {
      commit("setNowTime");
    },
    setToken ({ commit }, data) {
      commit("setToken", data);
    },
    setLoadingShow ({ commit }, data) {
      commit("setLoadingShow", data);
    },
    setEventDetail ({ commit }, data) {
      commit("setEventDetail", data);
    },
    setJointPhoto ({ commit }, data) {
      commit("setJointPhoto", data);
    },
    setTrtcData ({ commit }, data) {
      commit("setTrtcData", data);
    },
    setNickName ({ commit }, data) {
      commit("setNickName", data);
    },
    setEventExpress ({ commit }, data) {
      commit("setEventExpress", data);
    },
    setLoading ({ commit }, data) {
      commit("setLoading", data);
    },
    setEventId ({ commit }, data) {
      commit("setEventId", data);
    },
    setWinHeight ({ commit }, data) {
      commit("setWinHeight", data);
    },
    setWinWidth ({ commit }, data) {
      commit("setWinWidth", data);
    },
    setCountStartFlag ({ commit }, data) {
      commit("setCountStartFlag", data);
    },
    sendLog ({ commit }, data) {
      commit("sendLog", data);
    },
    checkDelay ({ commit }) {
      // debugger
      commit("checkDelay");
    },
    trySE ({ commit }) {
      commit("trySE");
    },
    async setLiverInfo ({ commit }) {
      await authApi.getProfile().then(res => {
        commit("setLiverInfo", res.data);
      }).catch(() => {
        commit("setLiverInfo", null);
      });
    },
  },
  modules: {},
});
