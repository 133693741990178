import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: '/login'

  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      shouldLogin: false,
      pageType: 'common'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/common/Login.vue')
  },
  {
    path: '/event-list',
    name: 'EventList',
    meta: {
      shouldLogin: true,
      pageType: 'common'
    },
    component: () => import(/* webpackChunkName: "EventList" */ '../views/common/EventList.vue')
  },
  {
    path: '/event-end/:id',
    name: 'EventEnd',
    meta: {
      shouldLogin: true,
      pageType: "meet",
    },
    component: () => import(/* webpackChunkName: "EventEnd" */ '../views/common/EventEnd.vue')
  },
  {
    path: "/meet/:id",
    name: "MeetIndex",
    component: () =>
      import(/* webpackChunkName: "Meet" */ "../views/meet/Index.vue"),
    meta: {
      shouldLogin: true,
      pageType: 'meet'
    },
    children: [
      {
        path: "meeting",
        component: () =>
          import(
            /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/Index.vue"
          ),
        children: [
          {
            path: "waittingroom",
            name: "WaittingRoom",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/WaittingRoom.vue"
              ),
            meta: {
              shouldLogin: true,
              pageType: 'meet'
            },
          },
          {
            path: "meeting",
            name: "Meeting",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/Meeting.vue"
              ),
            meta: {
              shouldLogin: true,
              pageType: 'meet'
            },
          },
          {
            path: "shootingroom",
            name: "ShootingRoom",
            component: () =>
              import(
                /* webpackChunkName: "Meet" */ "../views/meet/meetingPage/ShootingRoom.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "meet",
              haveTheme: true,
            },
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to)
  const shouldLogin = to.meta.shouldLogin
  let liverInfo = store.getters.liverInfo
  const token = store.getters.token
  const toPageType = to.meta.pageType
  const fromPageType = from.meta.pageType
  if (toPageType !== fromPageType && fromPageType === 'meet') {
    setTimeout(() => {
      location.reload()
    }, 200)
  }

  if (shouldLogin) {
    if (!token) {
      next({ name: 'Login' });
    } else {
      // 如果是需要登录的页面，在判断前记录下路由信息并存储到seesionStorage中
      if (liverInfo) {
        next()
      } else {
        await store.dispatch('setLiverInfo')
        liverInfo = store.getters.liverInfo
        if (liverInfo) {
          let checkDelayPromise = store.getters.checkDelayPromise
          if (checkDelayPromise === null) {
            store.dispatch('checkDelay')
          }
          checkDelayPromise = store.getters.checkDelayPromise
          await checkDelayPromise
          next()

        } else {
          store.dispatch('setToken', '')
          localStorage.removeItem('token')
          next({ name: 'Login' })
        }
      }
    }

  } else {
    next()
  }
})

export default router
