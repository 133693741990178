const userApiBaseUrl = process.env.VUE_APP_API_BASE_URL;

export default function (api) {
  return {
    login (data) {
      return api.post(userApiBaseUrl + "/login", data, {
      });
    },
    getEventList (data) {
      return api.get(userApiBaseUrl + "/events", {
        params: data,
        shouldJWT: true,
      });
    },
    getProfile () {
      return api.get(userApiBaseUrl + "/profile", {
        shouldJWT: true,
      });
    },
    syncTime () {
      return api.get(userApiBaseUrl + "/sync/time", {
        shouldJWT: true,
        noLoading: true,
      });
    },
    getEventLiving (eventId) {
      return api.get(userApiBaseUrl + `/events/${eventId}/living`, {
        shouldJWT: true,
      });
    },
    getEventsUserTickets (eventId) {
      return api.get(userApiBaseUrl + `/events/${eventId}/userTickets`, {
        shouldJWT: true,
        noLoading: true,
      });
    },
    postEventsStatus (eventId, data) {
      return api.post(userApiBaseUrl + `/events/${eventId}/status`, data, {
        shouldJWT: true,
        noLoading: true,
      });
    },
    postEventsPhoto (eventId, userTicketId, data) {
      return api.post(userApiBaseUrl + `/events/${eventId}/userTicket/${userTicketId}/photos`, data, {
        shouldJWT: true,
        noLoading: true,
      });
    },
    reflushAccount (data) {
      return api.post(userApiBaseUrl + `/refresh`, data, {
        shouldJWT: true,
        isReflush: true,
      });
    }
  };
}
