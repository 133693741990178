import { createAPI } from "./create-api";
import auth from "./module/auth";
import { showLoading, hideLoading, getRefreshToken } from "../utils/utils";
import store from "../store";
import router from "@/router";

const api = createAPI();
let refreshTokenPromise;
//请求拦截器
api.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    let shouldJWT = config.shouldJWT || data.shouldJWT;
    if (shouldJWT && store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
    }
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT;
    }
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    const status = error?.response?.status;
    switch (status) {
      case 400:
        const messages = error.response.data || [];
        messages.forEach((item) => {
          window.$toast.error(item, {
            position: "top",
          });
        });
        break;
      case 401:
        if (!error.config._retry && !error.config.isReflush) {
          // 如果服务器返回认证错误&&不是第二次重试&&不是刷新接口
          if (!refreshTokenPromise) {
            // 如果没有创建刷新token的promise的情况下创建一个刷新token的Promise
            error.config._retry = true;
            refreshTokenPromise = getRefreshToken().then(() => {
              refreshTokenPromise = null;
            });
          }
          // 返回一个刷新token的promise
          return refreshTokenPromise
            .then(() => {
              // 当刷新token的promise返回成功时重新尝试调用接口
              return api.request(error.config);
            })
            .catch(() => {
              // 刷新失败则退出并返回错误
              window.$toast.error(`認証失敗しました`, {
                position: "top",
              });
              return Promise.reject(error);
            });
        }
        return Promise.reject(error);
      default:
        window.$toast.error(`予期できないエラー、code:${status}`, {
          position: "top",
        });
        break;
    }
    return Promise.reject(error);
  }
);
export const authApi = auth(api);
