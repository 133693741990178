export const m = {
  // login

  // meetIndex
  meetIndexErrorTip: '必ずカメラとマイクのアクセスを許可してください',
  meetIndexErrorTip2: 'ネットワークまたはデバイスのエラーが発生しました。ページを再読み込みしてください。',
  meetIndexErrorTip3: 'カメラまたはマイクのエラーが発生しました。ページを再読み込みしてください。',
  meetIndexErrorTip4: '動画または音声にエラーが発生しました。',
  meetIndexErrorTip5: 'カメラまたはマイクのエラーが発生しました。もう一度許可してください。',
  meetIndexErrorTip6: 'カメラとマイクがブロックされています、カメラの許可または使用されているかどうかを確認してください。',
  meetIndexErrorTip7: 'カメラとマイクがブロックされています、カメラの許可または使用されているかどうかを確認してください。',
  meetIndexErrorTip8: 'ツーショットの有効期限が切れました！',
  meetIndexErrorTip9: 'マイクの初期化が失敗しました',
  meetIndexErrorTip10: 'イベントリストから参加してください',
  areyouReady: '準備は出来ましたか？',
  // Meeting
  meetingTip: 'ネットワークが不安定です。',
  meetingTip2: 'お確かめください。',
  meetingTip3: 'この後はツーショット撮影ルームへ',
  // ShootingRoom
  shootingRoomTip: 'ツーショット撮影ルーム',
  shootingRoomTip2: '撮影を準備しましょう。',
  shootingRoomTip3: 'カウントダウン終了後、撮影が自動的に行います。',
  shootingRoomTip4: '写真は二枚を連続撮影します。',
  // WaittingRoom
  waittingRoomStatus: 'テスト中',
  waittingRoomCameraTitle: 'カメラの映像',
  waittingRoomMicTest: 'マイクテスト',
  waittingRoomVoiceTest: '音量テスト',
  waittingRoomTip: '*注意事項',
  waittingRoomTip2: '・ネットワークの環境を安定させてください。',
  waittingRoomTip3: '・ミュート設定を外して音声を調整してください。 ',
  waittingRoomTip4: '音量テストボタンをタップして、音量を調整してください',
  // SettingDevice
  deviceSelection: '設備選択',
  camera: 'カメラ',
  mic: 'マイク',
  speaker: 'スピーカー',
  settingDeviceTip: 'カメラが他のソフトウェアによって呼び出されていないことを確認し、[次へ]をクリックしてください。',
  settingDeviceTip2: 'カメラを選択してください',
  settingDeviceTip3: '関連ソフトウェアを起動できるようになりました',
  // event list
  eventListTip: 'イベント情報がありません',
  // TopBar
  topBarTip: '順番待ちの参加者',
  // EventEnd
  eventEndCount: '参加人数',


  // common
  return: '戻る',
  no: 'いいえ',
  yes: 'はい',
  fan: 'ファン',
  errorTip: 'エラーが発生しました',
  remaining: 'あと',
  remaining2: '残り',
  user: 'ユーザー',
  thankyou: 'お疲れさまでした！',
  human: '人',
  logoutTip: '本当にログアウトしますか？',
  warning: '警告',

  day: '日',
  hours: '時間',
  minutes: '分',
  seconds: '秒',
  // 简写的时间
  d: '日',
  h: '時間',
  m: '分',
  s: '秒',

  YYYYMMDD: 'YYYY年MM月DD日',

  aboutTime: '約',
  more: 'もっと見る',
  send: '送信',
  reload: '再読み込み',
  recovery: '回復',
  permission: '許可',
  redo: 'やり直す',


  toNext: '次へ',
  toPre: '前へ',
  save: '保存',
  complete: '完成',



  loading: 'ローディング'
};