<template>
  <!-- :style="bgStyle" -->
  <div class="app-bg">
    <router-view />
    <Loading v-if="loadingShow" :text="$t('m.loading')"></Loading>
  </div>
</template>
<script>
import {
  computed,
  ref,
  onBeforeMount,
  onUnmounted,
  watch,
  inject,
  onMounted,
} from 'vue'
import store from '@/store'
import Loading from '@/components/Loading.vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Loading,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const toast = inject('toast')
    let resizeTimer = null
    // const winHeight = ref(0);
    const getWinHeight = () => {
      // winHeight.value = window.innerHeight;
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        store.dispatch('setWinHeight', window.innerHeight)
        store.dispatch('setWinWidth', window.innerWidth)
        console.log(window.innerHeight)
      }, 200)
    }
    const loadingShow = computed(() => {
      return store.getters.loadingShow
    })
    const bgStyle = computed(() => {
      return {
        height: store.getters.winHeight + 'px',
      }
    })

    // watch(token, (val) => {
    //   if (val) {
    //     joined();
    //   }
    // });

    onBeforeMount(() => {
      getWinHeight()
      window.addEventListener('resize', getWinHeight)
    })
    onMounted(() => {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
      store.dispatch('setNowTime')
      // setInterval(() => {
      //   console.log(window.innerHeight);
      // }, 1000);
    })
    onUnmounted(() => {
      window.removeEventListener('resize', getWinHeight)
    })
    return {
      bgStyle,
      loadingShow,
    }
  },
}
</script>
<style lang="less">
.app-bg {
  background-color: #191919;
  height: 100%;
}
</style>
