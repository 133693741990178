let loadingCount = 0
let loadingTimer = null
import store from "@/store";
import { authApi } from "@/api";
import { useRoute, useRouter } from "vue-router";
import initThemeData from "@/utils/defaultTheme.json"

const startLoading = () => {
    store.dispatch('setLoading', true)
}

const endLoading = () => {
    store.dispatch('setLoading', false)
}

export const showLoading = () => {
    clearTimeout(loadingTimer)
    loadingTimer = setTimeout(() => {
        loadingCount = 1
        hideLoading()
    }, 5000)
    if (loadingCount === 0) {
        startLoading()
    }
    loadingCount += 1
}

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        clearTimeout(loadingTimer)
        endLoading()
    }
}
export const nicnNameSubstring = (nickName) => {
    return nickName.substring(0, 2);
};
export const creatColorByNickName = (NickName) => {
    // nick name to code
    let id = 0;
    for (var i = 0; i < NickName.length; i++) {
        id += NickName.charCodeAt(i);
    }
    id = id % 100;
    const h = id * (360 / 100);
    const s = 80;
    const l = 30;
    return hslToHex(h, s, l);
};
export const hslToHex = (h, s, l) => {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `${f(0)}${f(8)}${f(4)}`;
};

export const isDuringDate = (beginDateOri, endDateOri) => {
    //
    let curDate = new Date().getTime() - store.getters.serverDelay
    let beginDate = new Date(beginDateOri).getTime()
    let endDate = new Date(endDateOri).getTime();
    if (curDate >= beginDate && curDate < endDate) {
        return true;
    }
    return false;
};

export const initEventData = () => {
    store.dispatch("setEventDetail", null);
    store.dispatch("setEventExpress", "");
    store.dispatch("setEventId", "");

    store.dispatch("setJointPhoto", null);
    store.dispatch("setIsBan", false);


    store.dispatch("setTrtcData", {
        key: "client",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "remoteStream",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "localStream",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "streamingInfo",
        value: null,
    });

    localStorage.removeItem("eventType");
}

export const getEvent = () => {
    const router = useRouter();
    const route = useRoute()
    const haveTheme = route.meta.haveTheme
    const joined = () => {
        authApi
            .events()
            .then((res) => {
                if (res.data.events.length === 0) {
                    router.replace({ name: "EnterCode" });
                    return false;
                }
                const { eventExpress, eventId } = res.data.events[0];
                const vuexEventId = store.getters.eventDetail?.eventId
                if (vuexEventId !== eventId) {
                    reTryGetEvent(eventId);
                } else {
                    if (haveTheme) {
                        setEventTheme()
                    }
                }
                store.dispatch("setEventExpress", eventExpress || "");
                store.dispatch("setEventId", eventId || "");
            })
            .catch((error) => {
                console.error(error);
                router.replace({ name: "EnterCode" });
            });
    };
    const reTryGetEvent = (id) => {
        authApi
            .eventsDetail(id)
            .then((res) => {
                store.dispatch("setEventDetail", res.data);
                localStorage.setItem("eventType", res.data.eventType);
                if (haveTheme) {
                    setEventTheme()
                }
            })
            .catch((error) => {
                console.error(error);
                window.$toast.error("参加できるイベントがないです", {
                    position: "top",
                });
                router.replace({ name: "EnterCode" });
            });
    };

    joined()
}

export const setEventTheme = () => {
    const themeData = store.getters.eventDetail?.theme

    if (themeData) {
        setTheme(themeData)
    } else {
        initTheme()
    }

}

export const setTheme = (themeData) => {
    Object.keys(themeData).forEach(key => {
        document.body.style.setProperty(`--${key}`, themeData[key])
    })
}

export const initTheme = () => {
    setTheme(initThemeData)
}

export const langList = [
    {
        label: '日本語',
        code: 'ja'
    },
    {
        label: 'English',
        code: 'en'
    }
]
export const getLang = () => {
    const cacheLocale = localStorage.getItem('locale')
    let lang = navigator.language || navigator.userLanguage
    if (cacheLocale) {
        lang = cacheLocale
    } else {
        lang = lang.substr(0, 2)
    }
    const data = langList.find((item) => {
        return item.code === lang
    })
    if (!data) {
        data = 'en'
    }
    return lang
}

export const getRefreshToken = () => {
    const params = {
        jwt: localStorage.getItem("token"),
    };
    return new Promise((resolve, reject) => {
        authApi
            .reflushAccount(params)
            .then((res) => {
                console.log(res);
                const token = res.data.jwt;
                store.dispatch("setToken", token);
                localStorage.setItem("token", token);
                resolve(token);
                // localStorage.setItem("token", res.data.jwt);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};