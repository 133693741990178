<template>
  <div class="common-loading flexCenter dflex">
    <div class="common-authing-body">
      <div class="common-authing item7">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
      </div>
      <div class="tc">{{ text }}</div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
