import { createI18n } from 'vue-i18n'
// 国际化
// let lang = getLang()
const i18n = createI18n({
    locale: 'ja',
    // localStorage.getItem("lang") || lang, // set locale
    fallbackLocale: 'ja',
    warnHtmlInMessage: 'off',
    messages: {
        ja: require('./ja.js'), // 日文语言包
    }
})

export default i18n
